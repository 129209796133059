.container {
	padding-top:25px;
}

.browser-container {
	background: url('/images/web-bg-placeholder-v2.png') no-repeat;
	width:100%;
	height:0px;
	padding-bottom:71%;
	background-size: 100%;
	position: relative;

	.browser {
		width:96.8%;
		position: absolute;
		left:0.6%;
		top:6.5%;
		height:87.4%;
		overflow: hidden;
		border-radius: 0px 0px 15px 15px;

		.sticky-cart-top-bottom {
			width:100%;
			position: absolute;
			left:0px;
			text-align: right;

			&.top {
				top:0px;
			}

			&.bottom {
				left:0.12%;
				bottom:5px;
			}

			img {
				width:100%;
				max-width:100%;
			}

			.banner {
				width:100%;

				select {
					width: auto !important;
					min-width: auto;
					padding: 6px 20px 6px 10px;
					line-height: 1.35;
				    border: none;
				    border-radius: 5px;
				    text-rendering: auto;
		    		color: initial;
		    		letter-spacing: normal;
		    		word-spacing: normal;
		    		text-transform: none;
		    		text-indent: 0px;
		    		text-shadow: none;
		   			display: inline-block;
		    		text-align: start;
		    		margin: 0em;
		    		font-size:11px;
		    		min-height:auto !important;
		    		height:auto !important;
		    		background-position: top right;
		    		margin-right:3px;
				}

				button {
					font-size:11px;
					min-height:auto;
					height: auto !important;
					background:none; border:none;
					box-shadow: none;
				}

				span {
					padding-right:10px;
					font-size:11px;
				}

				&__inner {
					padding:10px 15px;
				}
			}
		}
	}
}

.vc-chrome {
	position: absolute;
	top: 57px;
	right: 0;
	z-index: 99;
}
.current-color {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-color: #000;
	cursor: pointer;
	border:1px solid #e4e4e4;
}

.onboarding {
	background-image: url('/images/onboarding-bg.png');
	background-position: center;
	text-align: center;
	width:100%;
	height:100%;
	border:5px solid #fff;
	position: relative;

	.content {
		width:91%;
		position: absolute;
		border-radius:5px;
		background-color: rgba(255, 255, 255, 0.8);
		padding:25px;
		bottom:25px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}
}

button {
	&.btn {
		/* Button Reset */
		border: none;
		margin: 0;
		padding: 0;
		width: auto;
		overflow: visible;

		background: transparent;

		box-shadow: inherit;

		/* inherit font & color from ancestor */
		color: inherit;
		font: inherit;

		/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
		line-height: normal;

		/* Corrects font smoothing for webkit */
		-webkit-font-smoothing: inherit;
		-moz-osx-font-smoothing: inherit;

		/* Corrects inability to style clickable `input` types in iOS */
		-webkit-appearance: none;

		&:hover {
			background: none;
			box-shadow: none;
		}

		&.default {
			background-color: #fff;
			border-radius: 4px;
			box-sizing: border-box;
			padding: 10px;
			letter-spacing: 1px;
			font-family: Open Sans,sans-serif;
			font-weight: 400;
			min-width: 140px;
			margin-top: 8px;
			color: #8b8c8d;
			cursor: pointer;
			border: 1px solid #dddedf;
			text-transform: uppercase;
			transition: all .1s;
			font-size: 11px;
			outline: none;

			&:hover {
				background-color: darken(#FFF, 5%)
			}
		}

		&.primary {
			background-color: #1697a1;
			border-radius: 4px;
			box-sizing: border-box;
			padding: 10px;
			letter-spacing: 1px;
			font-family: Open Sans,sans-serif;
			font-weight: 400;
			min-width: 140px;
			margin-top: 8px;
			color: #FFF;
			cursor: pointer;
			border: 1px solid #dddedf;
			text-transform: uppercase;
			transition: all .1s;
			font-size: 11px;
			outline: none;

			&:hover {
				background-color: darken(#1697a1, 10%)
			}
		}
	}
}

.v-step {
	z-index:999 !important;
}

#drift-widget {
	bottom: 44px !important;
}

.stick_xhr2654654543_a3_c2 {
	width:50px;
	height:50px;
	z-index: 99999;
	margin:auto;

	a {
		display: block;
		width: 100%;
		height: 100%;
	}

	.stick_xhr2654654543_a3_circle_c2 {
		background-color: rgba(255, 255, 255, 0.9);
		position: relative;
		box-shadow: 1px 1px 5px grey;
		border-radius: 30px;
		width:100%;
		height:100%;

		 svg {
		 	vertical-align: middle;
		 	position: absolute;
		 	top: calc(50% - 10px);
		 	left: calc(50% - 12px);

		 	path {
		 		fill: #000000;
		 	}
		}

		.stick_xhr2654654543_a3_badge_c2 {
			min-width: 25px;
			border-radius: 20px;
			background-color: #d11111;
			color: #fff;
			min-height: 25px;
			position: absolute;
			top: -5px;
			left:-10px;
			text-align: center;
			border: 2px solid #fff;
			font-size:10px;
			line-height:15px;
			font-weight: bold;
			padding:3px 3px;
			font-family: inherit;
		}
	}
}